
export default {
  vessel: {
    search: {
      padding: 'sm',
      borderBottomWidth: 1,
      backgroundColor: 'grey-lighter'
    },
    contact: {
      padding: 'sm',
      borderTopWidth: 1,
      fontSize: 'sm',
      backgroundColor: 'grey-lighter'
    }
  },
  data() {
    return {
      showSearch: false,
      langSwitcher: true
    }
  },
  computed: {
    links() {
      const links = [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.products', route: 'categories' },
        { label: 'pages.catalogue', route: 'katalogos' },
        { label: 'pages.about', route: 'sxetika-me-tin-paouris-parts' },
        { label: 'pages.contact', route: 'epikoinonia' }
      ]

      if (this.$auth.loggedIn && this.$screen.max('xl')) {
        links.push({ label: 'eshop.orders.myOrders', route: '/orders' })
      }

      return links
    }
  },
  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch

      if (this.showSearch && this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focusInput()
        })
      }
    }
  }
}
