export default async (ctx, inject) => {
  const helpers = {
    imageUrl({ image, model }) {
      return image
        ? ctx.$assetUrl(`catalogs/${model}/${image}`)
        : '/part-image.svg'
    },
    manualUrl({ manual, model }) {
      return manual
        ? ctx.$assetUrl(`catalogs/${model}/${manual}`)
        : null
    },
    productPath({ slug }) {
      return ctx.$pagePath({
        name: 'product-id',
        params: { id: slug }
      })
    },
    categoryPath({ slug }) {
      return ctx.$pagePath({
        name: 'product-category-id',
        params: { id: slug }
      })
    }
  }

  inject('helpers', helpers)
}
