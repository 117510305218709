import { render, staticRenderFns } from "./Columns.vue?vue&type=template&id=6d9a011c"
import script from "./Columns.vue?vue&type=script&lang=js"
export * from "./Columns.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserSession: require('/opt/build/repo/components/UserSession.vue').default,ScSocialIcons: require('/opt/build/repo/node_modules/@evince/site/lib/packages/SocialIcons.vue').default,FooterColumn: require('/opt/build/repo/components/footer/Column.vue').default,ScContactDetails: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Details.vue').default,ScBusinessHours: require('/opt/build/repo/node_modules/@evince/site/lib/packages/BusinessHours.vue').default,ScContactButton: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Button.vue').default,Trustpilot: require('/opt/build/repo/components/Trustpilot.vue').default})
