
export default {
  vessel: {
    wrapper: {
      backgroundColor: 'white',
      padding: 'sm'
    },
    infoBar: {
      fontSize: 'sm',
      fontWeight: 'light'
    }
  }
}
