
import Taxonomy from '@/graphql/queries/taxonomy/Taxonomy'

export default {
  vessel: {
    social: {
      fontSize: 'md',
      color: 'primary',
      marginTop: 'sm',
      negativeMarginLeft: 'xs'
    },
    externalLink: {
      backgroundColor: 'grey-lightest',
      borderWidth: 1,
      borderRadius: true,
      fontWeight: 'semibold',
      fontSize: 'sm',
      paddingY: 'xs',
      paddingX: 'sm',
      lineHeight: 'sm',
      hover: {
        color: 'primary'
      }
    }
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      clientId: 'eshop',
      query: Taxonomy,
      variables: { where: { kind: 'category' }, children: 6 }
    })

    this.categories = data.children.map(taxon => ({
      label: taxon.name,
      translate: false,
      path: { name: 'categories-id', params: { id: taxon.slug } }
    }))
  },
  data() {
    return {
      quickLinks: [
        {
          path: '/categories',
          label: 'pages.products',
          translate: true
        },
        {
          path: '/katalogos',
          label: 'pages.catalogue',
          translate: true
        },
        {
          path: '/sxetika-me-tin-paouris-parts',
          label: 'pages.about',
          translate: true
        },
        {
          path: '/shopping-cart',
          label: 'eshop.shoppingCart',
          translate: true
        },
        {
          path: '/cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        },
        {
          path: '/oroi-proypotheseis',
          label: 'pages.terms',
          translate: true
        }
      ],
      categories: []
    }
  },
  computed: {
    socialAccounts() {
      return [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/paouris.gr'
        },
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/paouris_parts'
        },
        {
          name: 'Youtube',
          icon: 'youtube',
          url: 'https://www.youtube.com/c/paourisgr'
        },
        {
          name: 'Pinterest',
          icon: 'pinterest',
          url: 'https://www.pinterest.com/paourisGR'
        }
      ]
    }
  }
}
