
export default {
  async fetch() {
    const { $content, $i18n: { locale } } = this

    this.features = await $content(locale, 'features')
      .sortBy('slug')
      .fetch()
  },
  watch: {
    '$i18n.locale': '$fetch'
  },
  data() {
    return {
      features: []
    }
  },
  computed: {
    horizontal() {
      return this.$screen.lg
    }
  },
  methods: {
    borderRight(index) {
      return this.horizontal &&
        ((index + 1 == this.features.length) ? null : 1)
    },
    borderBottom(index) {
      return !this.horizontal
        && ((index + 1 == this.features.length) ? null : 1)
    }
  }
}
