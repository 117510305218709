import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2b2280ec"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionFeatures: require('/opt/build/repo/components/section/Features.vue').default,NewsletterSubscribe: require('/opt/build/repo/components/newsletter/Subscribe.vue').default,FooterColumns: require('/opt/build/repo/components/footer/Columns.vue').default,FooterCopyright: require('/opt/build/repo/components/footer/Copyright.vue').default,FooterNotice: require('/opt/build/repo/components/footer/Notice.vue').default})
