
export default {
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      borderTopWidth: 1
    }
  },
  watch: {
    loggedIn: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.remove('protected')
        } else {
          document.body.classList.add('protected')
        }
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$user.email
        && this.$user.email.match('@paouris.gr|@hardpixel.eu')
    }
  }
}
