export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopCart = () => import('../../node_modules/@evince/eshop/lib/packages/Cart.vue' /* webpackChunkName: "components/eshop-cart" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAdd = () => import('../../node_modules/@evince/eshop/lib/packages/CartAdd.vue' /* webpackChunkName: "components/eshop-cart-add" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddButton = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddButton.vue' /* webpackChunkName: "components/eshop-cart-add-button" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddForm.vue' /* webpackChunkName: "components/eshop-cart-add-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartDrawer = () => import('../../node_modules/@evince/eshop/lib/packages/CartDrawer.vue' /* webpackChunkName: "components/eshop-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const EshopCartForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartForm.vue' /* webpackChunkName: "components/eshop-cart-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartRemove = () => import('../../node_modules/@evince/eshop/lib/packages/CartRemove.vue' /* webpackChunkName: "components/eshop-cart-remove" */).then(c => wrapFunctional(c.default || c))
export const EshopCartToggle = () => import('../../node_modules/@evince/eshop/lib/packages/CartToggle.vue' /* webpackChunkName: "components/eshop-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const CarouselGallery = () => import('../../components/CarouselGallery.vue' /* webpackChunkName: "components/carousel-gallery" */).then(c => wrapFunctional(c.default || c))
export const CustomAlert = () => import('../../components/CustomAlert.vue' /* webpackChunkName: "components/custom-alert" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const Trustpilot = () => import('../../components/Trustpilot.vue' /* webpackChunkName: "components/trustpilot" */).then(c => wrapFunctional(c.default || c))
export const UserSession = () => import('../../components/UserSession.vue' /* webpackChunkName: "components/user-session" */).then(c => wrapFunctional(c.default || c))
export const BrandCarousel = () => import('../../components/brand/Carousel.vue' /* webpackChunkName: "components/brand-carousel" */).then(c => wrapFunctional(c.default || c))
export const BrandList = () => import('../../components/brand/List.vue' /* webpackChunkName: "components/brand-list" */).then(c => wrapFunctional(c.default || c))
export const BrandListMinimal = () => import('../../components/brand/ListMinimal.vue' /* webpackChunkName: "components/brand-list-minimal" */).then(c => wrapFunctional(c.default || c))
export const CatalogAdjascentLink = () => import('../../components/catalog/AdjascentLink.vue' /* webpackChunkName: "components/catalog-adjascent-link" */).then(c => wrapFunctional(c.default || c))
export const CatalogBannerLarge = () => import('../../components/catalog/BannerLarge.vue' /* webpackChunkName: "components/catalog-banner-large" */).then(c => wrapFunctional(c.default || c))
export const CatalogBannerSmall = () => import('../../components/catalog/BannerSmall.vue' /* webpackChunkName: "components/catalog-banner-small" */).then(c => wrapFunctional(c.default || c))
export const CatalogBrandsSelect = () => import('../../components/catalog/BrandsSelect.vue' /* webpackChunkName: "components/catalog-brands-select" */).then(c => wrapFunctional(c.default || c))
export const CatalogCard = () => import('../../components/catalog/Card.vue' /* webpackChunkName: "components/catalog-card" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoryProducts = () => import('../../components/catalog/CategoryProducts.vue' /* webpackChunkName: "components/catalog-category-products" */).then(c => wrapFunctional(c.default || c))
export const CatalogFormSection = () => import('../../components/catalog/FormSection.vue' /* webpackChunkName: "components/catalog-form-section" */).then(c => wrapFunctional(c.default || c))
export const CatalogList = () => import('../../components/catalog/List.vue' /* webpackChunkName: "components/catalog-list" */).then(c => wrapFunctional(c.default || c))
export const CatalogModelsSelect = () => import('../../components/catalog/ModelsSelect.vue' /* webpackChunkName: "components/catalog-models-select" */).then(c => wrapFunctional(c.default || c))
export const CatalogPDFManual = () => import('../../components/catalog/PDFManual.vue' /* webpackChunkName: "components/catalog-p-d-f-manual" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartsSelect = () => import('../../components/catalog/PartsSelect.vue' /* webpackChunkName: "components/catalog-parts-select" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartsSelectMobile = () => import('../../components/catalog/PartsSelectMobile.vue' /* webpackChunkName: "components/catalog-parts-select-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogProductDesktop = () => import('../../components/catalog/ProductDesktop.vue' /* webpackChunkName: "components/catalog-product-desktop" */).then(c => wrapFunctional(c.default || c))
export const CatalogProductForm = () => import('../../components/catalog/ProductForm.vue' /* webpackChunkName: "components/catalog-product-form" */).then(c => wrapFunctional(c.default || c))
export const CatalogProductImage = () => import('../../components/catalog/ProductImage.vue' /* webpackChunkName: "components/catalog-product-image" */).then(c => wrapFunctional(c.default || c))
export const CatalogProductMobile = () => import('../../components/catalog/ProductMobile.vue' /* webpackChunkName: "components/catalog-product-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogRelatedCarousel = () => import('../../components/catalog/RelatedCarousel.vue' /* webpackChunkName: "components/catalog-related-carousel" */).then(c => wrapFunctional(c.default || c))
export const CatalogTable = () => import('../../components/catalog/Table.vue' /* webpackChunkName: "components/catalog-table" */).then(c => wrapFunctional(c.default || c))
export const CatalogTaxonSelect = () => import('../../components/catalog/TaxonSelect.vue' /* webpackChunkName: "components/catalog-taxon-select" */).then(c => wrapFunctional(c.default || c))
export const CategoryCardVertical = () => import('../../components/category/CardVertical.vue' /* webpackChunkName: "components/category-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const CategoryList = () => import('../../components/category/List.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/footer/Column.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/footer/Columns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const FooterCopyright = () => import('../../components/footer/Copyright.vue' /* webpackChunkName: "components/footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterNotice = () => import('../../components/footer/Notice.vue' /* webpackChunkName: "components/footer-notice" */).then(c => wrapFunctional(c.default || c))
export const LayoutBase = () => import('../../components/layout/Base.vue' /* webpackChunkName: "components/layout-base" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutInfoBar = () => import('../../components/layout/InfoBar.vue' /* webpackChunkName: "components/layout-info-bar" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/newsletter/Subscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributes = () => import('../../components/product/Attributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductBrand = () => import('../../components/product/Brand.vue' /* webpackChunkName: "components/product-brand" */).then(c => wrapFunctional(c.default || c))
export const ProductCardHorizontal = () => import('../../components/product/CardHorizontal.vue' /* webpackChunkName: "components/product-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const ProductCardLoading = () => import('../../components/product/CardLoading.vue' /* webpackChunkName: "components/product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVertical = () => import('../../components/product/CardVertical.vue' /* webpackChunkName: "components/product-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const ProductCategory = () => import('../../components/product/Category.vue' /* webpackChunkName: "components/product-category" */).then(c => wrapFunctional(c.default || c))
export const ProductCompatibility = () => import('../../components/product/Compatibility.vue' /* webpackChunkName: "components/product-compatibility" */).then(c => wrapFunctional(c.default || c))
export const ProductCondition = () => import('../../components/product/Condition.vue' /* webpackChunkName: "components/product-condition" */).then(c => wrapFunctional(c.default || c))
export const ProductCountry = () => import('../../components/product/Country.vue' /* webpackChunkName: "components/product-country" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/product/Details.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductForm = () => import('../../components/product/Form.vue' /* webpackChunkName: "components/product-form" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/product/Gallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/product/Info.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductModel = () => import('../../components/product/Model.vue' /* webpackChunkName: "components/product-model" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product/Price.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/product/Related.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductSearch = () => import('../../components/product/Search.vue' /* webpackChunkName: "components/product-search" */).then(c => wrapFunctional(c.default || c))
export const ProductStatus = () => import('../../components/product/Status.vue' /* webpackChunkName: "components/product-status" */).then(c => wrapFunctional(c.default || c))
export const ProductSuggestions = () => import('../../components/product/Suggestions.vue' /* webpackChunkName: "components/product-suggestions" */).then(c => wrapFunctional(c.default || c))
export const ProductWarranty = () => import('../../components/product/Warranty.vue' /* webpackChunkName: "components/product-warranty" */).then(c => wrapFunctional(c.default || c))
export const SectionAbout = () => import('../../components/section/About.vue' /* webpackChunkName: "components/section-about" */).then(c => wrapFunctional(c.default || c))
export const SectionBase = () => import('../../components/section/Base.vue' /* webpackChunkName: "components/section-base" */).then(c => wrapFunctional(c.default || c))
export const SectionBrands = () => import('../../components/section/Brands.vue' /* webpackChunkName: "components/section-brands" */).then(c => wrapFunctional(c.default || c))
export const SectionBrandsCarousel = () => import('../../components/section/BrandsCarousel.vue' /* webpackChunkName: "components/section-brands-carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionCategories = () => import('../../components/section/Categories.vue' /* webpackChunkName: "components/section-categories" */).then(c => wrapFunctional(c.default || c))
export const SectionFeatures = () => import('../../components/section/Features.vue' /* webpackChunkName: "components/section-features" */).then(c => wrapFunctional(c.default || c))
export const SectionHeaderHome = () => import('../../components/section/HeaderHome.vue' /* webpackChunkName: "components/section-header-home" */).then(c => wrapFunctional(c.default || c))
export const SectionProductSimilar = () => import('../../components/section/ProductSimilar.vue' /* webpackChunkName: "components/section-product-similar" */).then(c => wrapFunctional(c.default || c))
export const SectionSuggestions = () => import('../../components/section/Suggestions.vue' /* webpackChunkName: "components/section-suggestions" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
