const loadContent = () =>
  import('./plugin.client.lazy' /* webpackChunkName: "catalog/plugin.js" */)

export default (ctx, inject) => {
  let $$catalog = null
  const { dbHash } = ctx.$config ? ctx.$config.catalog : ctx.nuxtState.catalog
  const $catalog = (...dbArgs) => {
    if ($$catalog) {
      return $$catalog(...dbArgs)
    }
    const keys = [
      'only',
      'without',
      'sortBy',
      'limit',
      'skip',
      'where',
      'search',
      'surround'
    ]
    const mock = {}
    const toCall = []
    for (const key of keys) {
      mock[key] = (...args) => {
        toCall.push({ key, args })
        return mock
      }
    }
    mock.fetch = async () => {
      const database = await fetch(
        `https://cdn.statica.eu/assets/paouris-parts/content/db-${dbHash}.json`
      ).then(res => res.json())
      $$catalog = (await loadContent()).default(database)
      let query = $$catalog(...dbArgs)
      toCall.forEach(({ key, args }) => {
        query = query[key](...args)
      })
      return query.fetch()
    }

    return mock
  }
  inject('catalog', $catalog)
  ctx.$catalog = $catalog
}
