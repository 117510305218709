export default async ({ app }) => {
  const options = {"token":"AQpCnnFDzA75mAf4wNy3z54H","position":"left","enabled":true}

  if (options.enabled !== false && typeof window !== 'undefined') {
    /* eslint-disable */
    (function(d,t) {
      var BASE_URL="https://app.chatwoot.com";
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=BASE_URL+"/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g,s);
      g.onload=function(){
        window.chatwootSettings = {
          position: options.position,
          locale: app.i18n.locale,
          ...options.settings
        }

        window.chatwootSDK.run({
          websiteToken: options.token,
          baseUrl: BASE_URL
        })
      }
    })(document,"script");
    /* eslint-enable */

    if (app.i18n) {
      app.i18n.__switchChatwootLocale = (oldLocale, newLocale) => {
        if (window.$chatwoot) {
          window.$chatwoot.setLocale(newLocale)
        }
      }
    }
  }
}
