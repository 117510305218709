
export default {
  vessel: {
    bar: {
      padding: 'sm',
      textAlign: 'center',
      borderTopWidth: 1,
      fontSize: 'xs'
    }
  }
}
