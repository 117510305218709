import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _39155039 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _63b058ee = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _0ae7bf9c = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/checkout.vue' /* webpackChunkName: "pages/eshop/checkout" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _77f43e64 = () => interopDefault(import('../pages/epikoinonia.vue' /* webpackChunkName: "pages/epikoinonia" */))
const _681eeacb = () => interopDefault(import('../pages/katalogos.vue' /* webpackChunkName: "pages/katalogos" */))
const _330b853b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders.vue' /* webpackChunkName: "pages/eshop/orders" */))
const _713e5c88 = () => interopDefault(import('../pages/oroi-proypotheseis.vue' /* webpackChunkName: "pages/oroi-proypotheseis" */))
const _516c7b85 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/payment-methods.vue' /* webpackChunkName: "pages/eshop/payment-methods" */))
const _66e3cd38 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _82f24fee = () => interopDefault(import('../pages/product-category/index.vue' /* webpackChunkName: "pages/product-category/index" */))
const _fc71dd5e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _407be469 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shipping-methods.vue' /* webpackChunkName: "pages/eshop/shipping-methods" */))
const _5bd9787f = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shopping-cart.vue' /* webpackChunkName: "pages/eshop/shopping-cart" */))
const _ec807c4a = () => interopDefault(import('../pages/sxetika-me-tin-paouris-parts.vue' /* webpackChunkName: "pages/sxetika-me-tin-paouris-parts" */))
const _0229a59b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/terms-of-service.vue' /* webpackChunkName: "pages/eshop/terms-of-service" */))
const _d6fc104c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _824a4a7c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _17fc0ce1 = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _c111b79e = () => interopDefault(import('../pages/categories/_id.vue' /* webpackChunkName: "pages/categories/_id" */))
const _44eda9c6 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders-id.vue' /* webpackChunkName: "pages/eshop/orders-id" */))
const _e6656e9e = () => interopDefault(import('../pages/product-category/_id.vue' /* webpackChunkName: "pages/product-category/_id" */))
const _b61884c0 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _4849980e = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _13972652 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/receipt.vue' /* webpackChunkName: "pages/eshop/receipt" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brands",
    component: _39155039,
    name: "brands___el"
  }, {
    path: "/categories",
    component: _63b058ee,
    name: "categories___el"
  }, {
    path: "/checkout",
    component: _0ae7bf9c,
    name: "checkout___el"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___el"
  }, {
    path: "/en",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/epikoinonia",
    component: _77f43e64,
    name: "epikoinonia___el"
  }, {
    path: "/katalogos",
    component: _681eeacb,
    name: "katalogos___el"
  }, {
    path: "/orders",
    component: _330b853b,
    name: "orders___el"
  }, {
    path: "/oroi-proypotheseis",
    component: _713e5c88,
    name: "oroi-proypotheseis___el"
  }, {
    path: "/payment-methods",
    component: _516c7b85,
    name: "payment-methods___el"
  }, {
    path: "/product",
    component: _66e3cd38,
    name: "product___el"
  }, {
    path: "/product-category",
    component: _82f24fee,
    name: "product-category___el"
  }, {
    path: "/products",
    component: _fc71dd5e,
    name: "products___el"
  }, {
    path: "/shipping-methods",
    component: _407be469,
    name: "shipping-methods___el"
  }, {
    path: "/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___el"
  }, {
    path: "/sxetika-me-tin-paouris-parts",
    component: _ec807c4a,
    name: "sxetika-me-tin-paouris-parts___el"
  }, {
    path: "/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___el"
  }, {
    path: "/auth/login",
    component: _d6fc104c,
    name: "auth-login___el"
  }, {
    path: "/auth/register",
    component: _824a4a7c,
    name: "auth-register___el"
  }, {
    path: "/en/brands",
    component: _39155039,
    name: "brands___en"
  }, {
    path: "/en/categories",
    component: _63b058ee,
    name: "categories___en"
  }, {
    path: "/en/checkout",
    component: _0ae7bf9c,
    name: "checkout___en"
  }, {
    path: "/en/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___en"
  }, {
    path: "/en/epikoinonia",
    component: _77f43e64,
    name: "epikoinonia___en"
  }, {
    path: "/en/katalogos",
    component: _681eeacb,
    name: "katalogos___en"
  }, {
    path: "/en/orders",
    component: _330b853b,
    name: "orders___en"
  }, {
    path: "/en/oroi-proypotheseis",
    component: _713e5c88,
    name: "oroi-proypotheseis___en"
  }, {
    path: "/en/payment-methods",
    component: _516c7b85,
    name: "payment-methods___en"
  }, {
    path: "/en/product",
    component: _66e3cd38,
    name: "product___en"
  }, {
    path: "/en/product-category",
    component: _82f24fee,
    name: "product-category___en"
  }, {
    path: "/en/products",
    component: _fc71dd5e,
    name: "products___en"
  }, {
    path: "/en/shipping-methods",
    component: _407be469,
    name: "shipping-methods___en"
  }, {
    path: "/en/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___en"
  }, {
    path: "/en/sxetika-me-tin-paouris-parts",
    component: _ec807c4a,
    name: "sxetika-me-tin-paouris-parts___en"
  }, {
    path: "/en/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___en"
  }, {
    path: "/en/auth/login",
    component: _d6fc104c,
    name: "auth-login___en"
  }, {
    path: "/en/auth/register",
    component: _824a4a7c,
    name: "auth-register___en"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___el"
  }, {
    path: "/en/brands/:id",
    component: _17fc0ce1,
    name: "brands-id___en"
  }, {
    path: "/en/categories/:id",
    component: _c111b79e,
    name: "categories-id___en"
  }, {
    path: "/en/orders/:id",
    component: _44eda9c6,
    name: "orders-id___en"
  }, {
    path: "/en/product-category/:id",
    component: _e6656e9e,
    name: "product-category-id___en"
  }, {
    path: "/en/product/:id",
    component: _b61884c0,
    name: "product-id___en"
  }, {
    path: "/en/products/:id",
    component: _4849980e,
    name: "products-id___en"
  }, {
    path: "/en/receipt/:id",
    component: _13972652,
    name: "receipt___en"
  }, {
    path: "/en/request/:id?",
    component: _7d1593c2,
    name: "request-id___en"
  }, {
    path: "/brands/:id",
    component: _17fc0ce1,
    name: "brands-id___el"
  }, {
    path: "/categories/:id",
    component: _c111b79e,
    name: "categories-id___el"
  }, {
    path: "/orders/:id",
    component: _44eda9c6,
    name: "orders-id___el"
  }, {
    path: "/product-category/:id",
    component: _e6656e9e,
    name: "product-category-id___el"
  }, {
    path: "/product/:id",
    component: _b61884c0,
    name: "product-id___el"
  }, {
    path: "/products/:id",
    component: _4849980e,
    name: "products-id___el"
  }, {
    path: "/receipt/:id",
    component: _13972652,
    name: "receipt___el"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
