
import loadJS from 'load-js'

export default {
  async mounted() {
    await loadJS({
      id: 'trustpilot',
      url: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      async: true
    })

    window.Trustpilot.loadFromElement(this.$refs.widget, true)
  }
}
