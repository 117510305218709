
export default {
  vessel: {
    copyright: {
      fontSize: 'sm',
      marginBottom: 'sm',
      xl: {
        paddingLeft: 'md',
        marginBottom: 0
      }
    }
  }
}
